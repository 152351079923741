import { useGuestStore } from "~/store/guest";
import { useAuthStore } from "~~/store/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();
  const guestStore = useGuestStore();
  if (to.path !== "/login") {
    const isAuthenticated = await authStore.isAuthenticated();
    const refreshToken = authStore.getRefreshToken();
    const isGuest = guestStore.getGuestUsername();
    if (!isAuthenticated && !refreshToken && !isGuest) {
      return navigateTo({
        path: "/login",
        query: { redirectUrl: from.fullPath },
      });
    }
    if (refreshToken) {
      await authStore.verifyAuthenticationAndRefreshTokens();
    }
  }
});
